import { Route, Routes } from "react-router-dom";
import { MainLayout } from "./components/layout";
import { Home } from "./components/pages";
import store from "./components/features/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import NetworkContext from "./components/hooks/networkContext";
import Proposal from "./components/pages/Proposal/Proposal";
import StakingList from "./components/pages/stakingList/StakingList";
import Community from "./components/pages/Community/Community";

let persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
        <NetworkContext>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="proposal" element={<Proposal />} />
              <Route path="staking-list" element={<StakingList />} />
              <Route path="community" element={<Community />} />
              <Route path={"*"} element={<>Page not found</>} />
            </Route>
          </Routes>
        </PersistGate>
       </NetworkContext>
      </Provider>
  );
}

export default App;
