import { Row, Col } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./FooterStyle.scss";

const Footer = () => {
  return (
    <Row className="footer">
      <Col span={24}>
        <ul className="footer__list">
          <li>
            <a href="https://traderjoexyz.com/trade?outputCurrency=0x822b906e74d493d07223cf6858620ccda66b2154#/" target={"_blank"}>Purchase tokens </a>
          </li>
          <li>
            <a href="https://www.coingecko.com/en/coins/rloop" target={"_blank"}>Coingecko </a>
          </li>
          <li>
            <a href="https://coinmarketcap.com/currencies/rloop/" target={"_blank"}>coin market cap</a>
          </li>
          <li>
            <a href="https://static1.squarespace.com/static/5a96c8e225bf021eda1e901e/t/6286c90434c61929bba831f5/1653000496197/WPpresentation.pdf" target={"_blank"}> whitepaper</a>
          </li>
          <li>
            <a href="https://static1.squarespace.com/static/5a96c8e225bf021eda1e901e/t/627d439c184c5c33fbc9b266/1652376480045/TokenGRAPH.pdf" target={"_blank"}> Tokenomics</a>
          </li>
          <li>
            <a href="https://snowtrace.io/address/0x822b906e74d493d07223cf6858620ccda66b2154" target={"_blank"}>Token contract</a>
          </li>
          <li>
            <a href="https://dexscreener.com/avalanche/0x6d4bdefe5b4644aff3617ed7ce2b7599e56fa135" target={"_blank"}> dextools</a>
          </li>
        </ul>
      </Col>
    </Row>
  );
};

export { Footer };
