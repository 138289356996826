import React from "react";
import { Link } from "react-router-dom";
import discord from "../../../assets/images/discord.png";
import fb from "../../../assets/images/fb.png";
import medium from "../../../assets/images/medium.png";
import reddit from "../../../assets/images/reddit.png";
import telegram from "../../../assets/images/telegram.png";
import pinterest from "../../../assets/images/pint.svg";
import twitter from "../../../assets/images/twitter.png";
import youtube from "../../../assets/images/youtube.png";
import "././SocialMediaStyle.scss";

const SocialMedia = (props) => {
  return (
    <div className="social__icons">
      <ul>
        <li>
          <Link to="/" target="_blank">
            <img src={pinterest} alt="social_icon" className="pint_icon" />
          </Link>
        </li>
        <li>
          <a href="https://discord.gg/KaBbSHMvSJ" target="_blank">
            <img src={discord} alt="social_icon" />
          </a>
        </li>
        <li>
          <a href=" https://twitter.com/rLoopDAO" target="_blank">
            <img src={twitter} alt="social_icon" />
          </a>
        </li>
        <li>
          <Link to="/" target="_blank">
            <img src={fb} alt="social_icon" />
          </Link>
        </li>
        <li>
          <Link to="/" target="_blank">
            <img src={reddit} alt="social_icon" />
          </Link>
        </li>
        <li>
          <Link to="/" target="_blank">
            <img src={youtube} alt="social_icon" />
          </Link>
        </li>
        <li>
          <a href="https://t.me/rloopcommunity" target="_blank">
            <img src={telegram} alt="social_icon" />
          </a>
        </li>
        <li>
          <Link to="/" target="_blank">
            <img src={medium} alt="social_icon" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export { SocialMedia };
