import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import liquidity from "../../../assets/abi/liquidity.json";
import nft from "../../../assets/abi/nft.json";
import staking from "../../../assets/abi/staking.json";
import treasury from "../../../assets/abi/treasury.json";
import advisory from "../../../assets/abi/Advisory.json";
import foundation from "../../../assets/abi/Foundation.json";
import Ecosystem from "../../../assets/abi/Ecosystem.json";
import { ContractServices } from '../../../services/contractService';
import { toast } from '../../layout/Toasts/Toast';
import { NftCont, StakingCont, LiquidityCont, EcosystemCont, FoundationCont, AdvisoryCont, TreasuryCont, seed, chainID } from '../../../constants';
import { walletServices } from '../../../services/walletServices';

const tokenclaimData = [
  {
    id: 4,
    lockPeriod: 5,
    saletype: "Treasury",
    address: TreasuryCont,
    abi: treasury,
  },
  {
    id: 5,
    lockPeriod: 5,
    saletype: "Advisory",
    address: AdvisoryCont,
    abi: advisory,
  },
  {
    id: 6,
    lockPeriod: 5,
    saletype: "Foundation",
    address: FoundationCont,
    abi: foundation,
  },
  {
    id: 7,
    lockPeriod: 5,
    saletype: "R&D Ecosystem",
    address: EcosystemCont,
    abi: Ecosystem,
  },
  {
    id: 8,
    lockPeriod: 5,
    saletype: "Seed",
    address: seed,
    abi: treasury,
  },
  {
    id: 1,
    lockPeriod: 5,
    saletype: "Liquidity",
    address: LiquidityCont,
    abi: liquidity,
  },
  {
    id: 3,
    lockPeriod: 5,
    saletype: "Staking",
    address: StakingCont,
    abi: staking,
  },
  {
    id: 2,
    lockPeriod: 5,
    saletype: "Nft",
    address: NftCont,
    abi: nft,
  },
];

export const fetchTokenClaim = createAsyncThunk(
  "project/tokenClaim",
  async (address) => {
    try {
      let data = [];
      let count = 0;
      let result = await new Promise(async(resolve,reject)=>{
          for(let i=0; i<tokenclaimData.length;i++){
            let getresult = await ContractServices.claimToken(
              tokenclaimData[i].saletype,
              tokenclaimData[i].address,
              tokenclaimData[i].abi,
              tokenclaimData[i].lockPeriod,
              address
            )
            if(getresult==false){
              if(count==0){
                const web3 = await walletServices.callWeb3()
                const networkId = await web3.eth.getChainId();
                networkId!=chainID &&  toast.error("Select the Avalanche Network");
                count = 1;
                
              }
              reject();
            }
            if(getresult.amount!=0){
              data.push(getresult);
            }
          }
        resolve(data);
      })
     return result;
    } catch (error) {
      console.log(error)
      // toast.error(error.message);
    }
  }
);



export const addressSlice = createSlice({
  name: 'stake',
  initialState: {
    address: "",
    loader:false,
    tokenClaim:[],
    SNTokenClaim:[],
  },
  reducers: {
    ownerAddress: (state, action) => {
      state.address = action.payload
      sessionStorage.setItem('w_address',action.payload)
    },
    tokenClaimEmpty:(state,action) =>{
      state.tokenClaim=""
    }
  },
    extraReducers: (builder) => {
      builder.addCase(fetchTokenClaim.pending, (state, action) => {
        state.loader = true
      });
      builder.addCase(fetchTokenClaim.fulfilled, (state, action) => {
        state.tokenClaim = action.payload;
        state.loader = false
      });
      builder.addCase(fetchTokenClaim.rejected, (state, action) => {
        state.tokenClaim = []
        state.loader = false
      });
     
    }
})

export const { ownerAddress,tokenClaimEmpty } = addressSlice.actions

export default addressSlice.reducer