import React, { useContext, useEffect, useState } from "react";
import { Row, Menu, } from "antd";
import { CommonButton } from "../button/CommonButton";
import { NavLink } from "react-router-dom";
import ConnectWallet from "../connectWallet/ConnectWallet";
import { SocialMedia } from "../socialMedia/SocialMedia";
import "./HeaderStyle.scss";
import { useDispatch, useSelector } from "react-redux";
import { ownerAddress } from "../../features/address/addressSlice";
import { toast } from "../Toasts/Toast";
import { walletServices } from "../../../services/walletServices";

const Header = () => {
  const { address } = useSelector((s) => s.address);
 
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const showModal = () => {
    setIsModalVisible(true);
  };

  const disconnectWlt = () => {
    dispatch(ownerAddress(""));
    walletServices.disconnectWalletCnnt()
    toast.success("Wallet Disconnected");
  };

  const [isMobile, setMobile] = useState(false);
  const toggleClass = () => {
    setMobile(!isMobile);
  };

  const handleClick = (e) => {
    const { linkDisabled } = this.state
    if(linkDisabled) e.preventDefault()
  }

  const [classToggle, setClassToggle] = useState(false);

  return (
    <>
      <Row className="nav_area">
        <div className="flex_draw">
          <div className={`headerStyle ${isMobile ? "mobileNav" : ""}`}>
            <Menu defaultSelectedKeys={["mail"]}>
              <Menu.Item key="one">
                <NavLink
                  to=""
                  className={({ isActive }) =>
                    isActive ? "selected" : "normal"
                  }
                >
                  {" "}
                  Community{" "}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="two">
                <NavLink
                  to=""
                  className={({ isActive }) =>
                    isActive ? "selected" : "normal"
                  }
                >
                  proposal
                </NavLink>
              </Menu.Item>
              <Menu.Item key="three">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "selected" : "normal"
                  }
                >
                  Dashboard
                </NavLink>
              </Menu.Item>
            </Menu>
            <div className="social_sides">
              <SocialMedia />
            </div>
          </div>
          <CommonButton
            className="clrd_btn"
            onClick={() => (!address ? showModal(true) : disconnectWlt())}
            title={
              address
                ? `${address.slice(0, 7)}...${address.slice(37)}`
                : "Connect Wallet"
            }
          />
        </div>
        <ConnectWallet
          isModalVisible={isModalVisible}
          showModal={showModal}
          handleCancel={handleCancel}
          onCancel={handleCancel}
        />
      </Row>
      <div className="mobile_drawer" onClick={toggleClass}>
        <button
          className={`menu_toggle ${classToggle ? "open" : ""}`}
          onClick={() => setClassToggle(!classToggle)}
        >
          <div className="wrap_icon">
            <span className="icon_left"></span>
            <span className="icon_right"></span>
          </div>
        </button>
      </div>
    </>
  );
};

export { Header };
