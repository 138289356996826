import { Row } from "antd";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";
import { SocialMedia } from "../socialMedia/SocialMedia";
import { Layout } from "antd";
import { useDispatch } from "react-redux";
import { ownerAddress } from "../../features/address/addressSlice";

const MainLayout = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if(!sessionStorage.getItem("w_address")){
  //     dispatch(ownerAddress(""))
  //   }
  // }, [])
  
  return (
    <>
      <Header />
      <Content>
        <SocialMedia />
        <Outlet />
      </Content>
      <Footer />
    </>
  );
};

export { MainLayout };
