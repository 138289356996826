import { Card ,Popover} from "antd";
import React, { useEffect, useState } from "react";
import { CommonButton } from "../button/CommonButton";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Skeleton } from "antd";
import RingLoader from "react-spinners/RingLoader";
import "./TokenClaimStyle.scss";
import { ContractServices } from "../../../services/contractService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../Toasts/Toast";
import { fetchTokenClaim, ownerAddress } from "../../features/address/addressSlice";
import { numberWithCommas } from "../../../constants/abis";
import { chainID } from "../../../constants";
import { walletServices } from "../../../services/walletServices";
import { isMobile } from "react-device-detect";
import { InfoCircleOutlined } from "@ant-design/icons";
import { contentTknClaim } from "../../../helpers";

const TokenClaim = (props) => {
  
  const { address, tokenClaim ,loader} = useSelector((s) => s.address);
  const dispatch = useDispatch(); 

  const handleClaim = async (name) => {
    if (address == "") {
      toast.error("Connect Wallet");
      return;
    }
    if(window.ethereum && (window.ethereum?.networkVersion != chainID)){
      return;
    }
    const result = await ContractServices.claimAmount(
      {
        name,
        waddress:address
       }
    );
    if (result) {
      toast.success('Token Claimed Successfully')
      dispatch(fetchTokenClaim(address));
    } 
  };

  return (
    <>
      <Card className={`tokenclaim ${props.className}`} bordered={false}>
        <div className="tokenclaim__headtitle">
          <h3>rLoop TOKEN CLAIMS<Popover content={contentTknClaim} title="Note">
            <InfoCircleOutlined
            className="stakecard__infoIcon"
            />
          </Popover></h3>
        </div>
        <div className="tokenclaim__details">
          <PerfectScrollbar>
            <ul className="tokenclaim__details__list">
              {loader ? (
                <div className="ringLoader">
                  <RingLoader color={"#ffffff"} loading={true} size={40} />
                </div>
              ) : (
                <>
                  {(address && tokenClaim?.length > 0) ?
                    tokenClaim.map((item, id) => 
                    {return(
                      <li key={id}>
                        <div className="sale_typ">{item?.name}</div>
                        <div className="rl_vale">{item?.amount==0?0:numberWithCommas(item?.amount)} rLoop</div>
                        <div className="claim_btn">
                          <CommonButton
                            disabled={item?.amount == 0 && true}
                            title="claim"
                            onClick={() =>
                              handleClaim(item?.name)
                            }
                          />
                        </div>
                      </li>
                    )}) : <div className="ringLoader">No tokens to be claimed</div>}
                </>
              )}
              
            </ul>
          </PerfectScrollbar>
        </div>
      </Card>
    </>
  );
};

export default TokenClaim;
