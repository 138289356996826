import liquidity from "../assets/abi/liquidity.json";
import nft from "../assets/abi/nft.json";
import staking from "../assets/abi/staking.json";
import treasury from "../assets/abi/treasury.json";
import advisory from "../assets/abi/Advisory.json";
import foundation from "../assets/abi/Foundation.json";
import Ecosystem from "../assets/abi/Ecosystem.json";
import { AdvisoryCont, EcosystemCont, FoundationCont, LiquidityCont, NftCont, seed, StakingCont, TreasuryCont } from ".";

export const tokenclaimData = {
  "Treasury":{
      id: 4,
      lockPeriod: 5,
      address: TreasuryCont,
      abi: treasury,
    },
    "Advisory":{
      id: 5,
      lockPeriod: 5,
      address:AdvisoryCont,
      abi:advisory,
    },
    "Foundation":{
      id: 6,
      lockPeriod: 5,
      address: FoundationCont,
      abi:foundation,
    },
    "R&D Ecosystem":{
      id: 7,
      lockPeriod: 5,
      address: EcosystemCont,
      abi:Ecosystem,
    },
    "Seed":{
      id: 8,
      lockPeriod: 5,
      saletype: "Seed",
      address: seed,
      abi: treasury,
    },
    "Liquidity":{
      id: 1,
      lockPeriod: 5,
      address: LiquidityCont,
      abi:liquidity,
    },
    "Staking":{
      id: 3,
      lockPeriod: 5,
      address: StakingCont,
      abi:staking,
    },
    "Nft":{
      id: 2,
      lockPeriod: 5,
      address: NftCont,
      abi: nft,
    },
  };

export function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
} 