import { Card, Row, Col } from "antd";
import React from "react";
import { CommonButton } from "../button/CommonButton";
import "./TokenClaimStyle.scss";

const RevenueClaim = (props) => {
  const revenuclaimData = [
    {
      id: 1,
      projectno: "01",
      value: "10",
    },
    {
      id: 2,
      projectno: "02",
      value: "15",
    },
    {
      id: 3,
      projectno: "03",
      value: "20",
    },
  ];

  return (
    <Card className="tokenclaim revnueclaim" bordered={false}>
      <div className="tokenclaim__headtitle">
        <h3>REVENUE CLAIM</h3>
      </div>
      <div className="tokenclaim__details revnueclaim__details">
        <ul className="tokenclaim__details__list revnueclaim__details__list">
          {revenuclaimData.map((item, id) => (
            <li key={id}>
              <div className="data">Project {item.projectno}:</div>
              <div className="vlue">{item.value} USDC</div>
              <div className="claim_btn">
                <CommonButton disabled={true} title="claim" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default RevenueClaim;
