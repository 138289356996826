import React, { useEffect, useState } from "react";
import { Row, Col, Space, Table ,Button, Popover} from "antd";
import "./StakingListStyle.scss";
import { CommonButton } from "../../layout/button/CommonButton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContractServices } from "../../../services/contractService";
import { toast } from "../../layout/Toasts/Toast";
import {
  InfoCircleOutlined
} from '@ant-design/icons';
import { calc, contentPop, nextClaim } from "../../../helpers";
const { Column } = Table;


const StakingList = (props) => {
  const {address} = useSelector(s=>s.address);
  const dispatch = useDispatch();
  const [sDetail, setsDetail] = useState([]);
  const [loader, setloader] = useState(false)
  useEffect(() => {
    document.body.classList.add("stakin_list_page");
    return () => {
      document.body.classList.remove("stakin_list_page");
    };
  }, []);

  useEffect(() => {
    stakingDetail()
  }, [address]);

  const stakingDetail =async()=>{
    setloader(true)
    if(address == ""){
      setloader(false)
      setsDetail([]);
      return;
    }
    const stakeCount  = await ContractServices.getStakeCount(address);
    let data= [];
    for(let i =1;i<=stakeCount;i++){
      const detail = await ContractServices.stakingDetail(address,i);
      data.push({...detail,amount:calc(detail.amount)});
    }
    setsDetail(data);
    setloader(false)
  }

  const handleClaim=async(id)=>{
    setloader(true)
   const claimresult = await ContractServices.claimStakeAmt(address,id);
   stakingDetail();
   setloader(false)
  }

  const handleUnStake=async(id,amount)=>{
    setloader(true)
    const claimresult = await ContractServices.unstakeAmt(id,address);
    if(claimresult){
      stakingDetail();
      toast.success('Token Unstaked Successfully')
    }
    setloader(false)
  }

  const claimInfo = () =>{
    return (<> 
        <span> Next Claim Date <Popover content={nextClaim} title="Note" >
        <InfoCircleOutlined  style={{
            fontSize: '15px',
          }}/>
      </Popover></span>
     </> )
  }
 
  
  return (
    <div className="staking_Page">
      <div className="titleSec">

        <h2>Staking History     
         <span> <Popover content={contentPop} title="Note" >
          <InfoCircleOutlined  style={{
              fontSize: '20px',
            }}/>
        </Popover></span>
        </h2>
       
      </div>
      <Row className="staking_Page_detail" gutter={10} >
        <Col xl={17} lg={24} md={24} sm={24} xs={24}>
          <Table dataSource={sDetail} scroll={{ x: 991 }} loading={loader}>
            <Column title="Amount" dataIndex="amount" key="amount" />
           
            <Column title="Stake Time" dataIndex="time" key="time" />
            <Column
              title="Time to Reward"
              dataIndex="rewardtime"
              key="rewardtime"
            />
            <Column title="Rewards" dataIndex="rewards" key="rewards" />
            <Column title="Stake Date" dataIndex="stakeDate" key="stakeDate" />
            <Column title={()=>claimInfo()} 
              dataIndex="claimDate"
              key="claimDate" />
            <Column
              title="Action"
              key="action"
              render={(record) => (
                <Space size="middle">
                  {console.log(record.daysDifference,"terererer")}
                  <CommonButton className="claimbtn" disabled={record.amount==0 || record.rewards==0}  title="Claim" onClick={()=>handleClaim(record.sNo)} />
                  <CommonButton title="Unstake" disabled={record.amount==0 || record.daysDifference < 30} onClick={()=>handleUnStake(record.sNo,record.amount)} />
                </Space>
              )}
            />
          </Table>
          <Link className="back_home" to="/">
            Back to Home
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default StakingList;
