import React from "react";
import { Button } from "antd";
import "./CommonButtonStyle.scss";

const CommonButton = (props) => {
  return (
    <Button {...props}  type="primary">
      {props.title}
    </Button>
  );
};

export { CommonButton };
