import React, { useState } from "react";
import "./CardSec.scss";
import { Anchor, Modal, Row, Col } from "antd";
import { CommonButton } from "../button/CommonButton";

const { Link } = Anchor;
const CardSec = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const data = [
    {
      name: "xyz",
      Link: "h688JuTrfd4678JHyG54478Ux567Kljms78Udsf34kkKLN4X",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      commit: "234543 rLoop",
    },
    {
      name: "xyz",
      Link: "h688JuTrfd4678JHyG54478Ux567Kljms78Udsf34kkKLN4X",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      commit: "234543 rLoop",
    },
  ];

  return (
    <>
      <div>
        {data.map((user, index) => (
          <div className="cardDetails" key={index}>
            <div className="title">
              <h4>
                PROPOSAL TITTLE <span>{user.name}</span>
              </h4>
            </div>
            <div className="proposelSec mt">
              <h4>PROPOSAL BY</h4>
              {/* <Anchor>
                <Link
                  className="linksec"
                  href="/"
                  title="h688JuTrfd4678JHyG54478Ux567Kljms78Udsf34kkKLN4X"
                ></Link>
              </Anchor> */}
              <a href="/" className="linksec">
                {user.Link}
              </a>
            </div>
            <div className="discriptionSec mt">
              <h4>
                DESCRIPTION
                <span>{user.description}</span>
              </h4>
            </div>
            <div className="commitedSec mt">
              <h4>
                TOTAL rLoop COMMITED<span>{user.commit}</span>
              </h4>
            </div>
            <div className="viewdetailSec mt">
              <a href="/">view details</a>
              <CommonButton
                onClick={showModal}
                className="stakebutton"
                title="STAKE"
                size="88px"
              />
            </div>
            <Modal
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              width={982}
              className="stakeModal"
            >
              <Row className="modal">
                <Col lg={12}>
                  <div>
                    <h4 className="modal-title  mb">
                      PROPOSAL TITTLE <span>XYZ</span>
                    </h4>
                    <h4 className="modal-proposal mb">
                      PROPOSAL TYPE <span> New Project</span>
                    </h4>
                    <h4 className="modal-ProposalBy mb">
                      PROPOSAL BY
                      <span className="span-padding ">
                        <a href="/" className="linksec">
                          h688JuTrfd4678 JHyG54478Ux567Kljms78Udsf3 4kkKLN4X
                        </a>
                      </span>
                    </h4>
                    <h4 className="total-staked  mb">
                      TOTAL STAKED
                      <span className="span-padding"> 34454 rLoop</span>
                    </h4>
                    <h4 className="mb">
                      MINIMUM <span className="span-padding"> 300 rLoop </span>
                    </h4>
                    <h4>
                      MAXIMUM <span className="span-padding"> 300 rLoop </span>
                    </h4>
                  </div>
                </Col>
                <Col lg={12}>
                  <h4 className="mb">
                    EMAIL <span className="span-padding"> ABC@CDE.COM </span>
                  </h4>
                  <div className="discription ">
                    <h4>DESCRIPTION</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit, sed do eiusmod tempor incididunt ut labore et dolore
                      magna aliqua.
                    </p>
                  </div>
                  <h4 className="mb byCol">
                    BY
                    <span className="span-padding ">
                      <a href="/" className="linksec">
                        h688JuTrfd4678JHyG5447 8Ux567Kljms78Udsf34kkKLN4X
                      </a>
                    </span>
                  </h4>
                  <h4>
                    BY
                    <span className="span-padding ">
                      <a href="/" className="linksec">
                        h688JuTrfd4678JHyG5447 8Ux567Kljms78Udsf34kkKLN4X
                      </a>
                    </span>
                  </h4>
                </Col>
              </Row>
              <div className="modalstakeButton">
                <CommonButton title="STAKE" />
              </div>
            </Modal>
          </div>
        ))}
      </div>
    </>
  );
};

export default CardSec;
