import React, { useEffect } from "react";
import "./Community.scss";
import { Col, Row } from "antd";
import { CommonButton } from "../../layout/button/CommonButton";


const Community = () => {
  useEffect(() => {
    document.body.classList.add("community_page");
    return () => {
      document.body.classList.remove("community_page");
    };
  }, []);
  return (
    <div className="communityPage">
      <div>
        <h1>
          LOREM IPSUM <br /> DOLOR SIT
        </h1>
        <p>
          rLoop is a decentralized organization accelerating technological
          solutions. to global problems. rLoop acquires, supports, and finances
          early-stage innovation and research projects as an open and
          self-governing collective. To get the most out of your experience, we
          encourage you to join us on Discord, where our governance discussions
          for proposals take place.
        </p>
        <CommonButton title="JOIN US" />
        <CommonButton className="Contactus" title="CONTACT US" />
      </div>
    </div>
  );
};

export default Community;
