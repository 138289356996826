import { toast } from "../components/layout/Toasts/Toast";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { chainID } from "../constants";
import { isMobile } from "react-device-detect";
import { ownerAddress } from "../components/features/address/addressSlice";


let walletTypeObject = "Metamask";
let web3Object;


export  let provider = new WalletConnectProvider({
    rpc: {
      43114: "https://api.avax.network/ext/bc/C/rpc",
    },
    qrcode: true,
    qrcodeModalOptions: {
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
      ],
      desktopLinks: [
        "encrypted ink",
      ]
    }
  });
  

const accountsChanged = () => {
  return async (dispatch) => {
    const { ethereum } = window;
    if (ethereum) {
      ethereum.on("accountsChanged", (accounts) => {
        dispatch(ownerAddress(accounts[0]));
        // window.location.reload();
      });
      ethereum.on("networkChanged", (networkId) => {
        window.location.reload();
      });
    }
  }
};

const isMetamaskInstalled = async () => {
    const { ethereum } = window;
    const result = Boolean(ethereum && ethereum.isMetaMask);
    walletTypeObject = "Metamask";
    if (result) {
      //metamask
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        return accounts[0];
      } catch (err) {
        toast.error(err.message);
        return false;
      }
    }else if(provider){  
      const account = walletConnect()
      return account;
    }
    else {
      const quote = 'Please install and initialize Metamask wallet extension first';
      toast.error(quote);
      setTimeout(() => {
        window.location.href = 'https://metamask.io/';
      }, 2000);
      return false;
    }
};

const callWeb3 = async () => {
    const { ethereum, web3, BinanceChain } = window;
    return new Promise(async(resolve,reject)=>{
      if (ethereum && ethereum.isMetaMask) {
        web3Object = new Web3(ethereum);
      } else if (ethereum) {
        web3Object = new Web3(ethereum);
      } else if (web3) {
        web3Object = new Web3(web3.currentProvider);
      } 
      else if(provider){  
        web3Object = new Web3(provider);
      }
      else {
        toast.error("You have to install MetaMask!");
        return;
      }
      resolve(web3Object);
    })
  };



const walletConnect = async() =>{
  try {
    const account  = await provider.enable();
    return account[0];
  } catch (error) {
      // window.location.reload();
      provider = new WalletConnectProvider({
        rpc: {
          43114: "https://api.avax.network/ext/bc/C/rpc",
        },
        qrcode: true,
        qrcodeModalOptions: {
          mobileLinks: [
            "rainbow",
            "metamask",
            "argent",
            "trust",
          ],
          desktopLinks: [
            "encrypted ink",
          ]
        }
      });
    } 
}

const disconnectWalletCnnt =async()=>{
  await provider.disconnect()
}

const networkSwitch = async() =>{
    try {
      const Browsprovider = window.ethereum;
      // const result = isMetamaskInstalled();
      if(Browsprovider){
        await Browsprovider.request({
          method: 'wallet_addEthereumChain',
          params: [
            { chainId: Web3.utils.toHex(chainID), 
              chainName:'Avalanche Mainnet',
              rpcUrls:['https://api.avax.network/ext/bc/C/rpc'],                  
              blockExplorerUrls:['https://snowtrace.io/'],  
              nativeCurrency: { 
                symbol:'AVAX',   
                decimals: 18
              }        
            }
          ]}
        );
      }
    } catch (addError) {
      window.location.reload();
      console.log(addError);
      toast.error(addError);
      return false;
  }
}

export const walletServices = {
    isMetamaskInstalled,
    callWeb3,
    walletConnect,
    networkSwitch,
    accountsChanged,
    disconnectWalletCnnt
}