export const contentPop = (
    <div>
      <p>Claim/Unstake will get active once your staking time period will be over.</p>
    </div>
  );

  export const nextClaim = (
    <div>
      <p>The rewards are distributed after every cycle of 30 days. If you unstake before completion of 30 days then no rewards will be</p> 
      <p>generated for that cycle. The Principle staked amount can be unstaked/withdrawn after 30 days of staking.</p>
    </div>
  );

  export const contentTknClaim = (
    <div>
      <p>This claim is only for seed and pre-sale investors.</p>
    </div>
  );

  export function calc(num) {  
      var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return with2Decimals;
}