import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Statistic,
  Anchor,
  Modal,
  Input,
  Radio,
  Form,
  Button,
} from "antd";
import "./Proposal.scss";
import CardSec from "../../layout/CardSec/CardSec";
import { CommonButton } from "../../layout/button/CommonButton";
// type LayoutType = Parameters<typeof Form>[0]["layout"];

const { TextArea } = Input;

const Proposal = (props) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
      : null;

  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: { span: 14, offset: 4 },
        }
      : null;

  const [value, setValue] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    initialDays = 12,
    initialHour = 5,
    initialMinute = 10,
    initialSeconds = 20,
    handleTimeOut,
  } = props;
  const [days, setDays] = useState(initialDays);
  const [hours, setHours] = useState(initialHour);
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          // handleTimeOut(true);
          // clearInterval(myInterval);
          setHours(24);
          if (hours === 0) {
            // handleTimeOut(true);
            setDays(days - 1);
            // clearInterval(myInterval);
          } else {
            setHours(hours - 1);
            // setMinutes(minutes - 1);
            setSeconds(59);
            setMinutes(59);
          }
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    document.body.classList.add("proposal_page");
    return () => {
      document.body.classList.remove("proposal_page");
    };
  }, []);

  return (
    <div className="proposelPage">
      <div className="titleSec">
        <h2>next round in</h2>
        <div>
          {hours === 0 && minutes === 0 && seconds === 0 ? null : (
            <p className="timer">
              <div className="mr">
                <span className="day">{days < 10 ? `0${days}` : days}</span>
                days
              </div>
              <div className="mr">
                <span className="hour">
                  : {hours < 10 ? `0${hours}` : hours}
                </span>
                Hour
              </div>
              <div className="mr">
                <span className="min">
                  : {minutes < 10 ? `0${minutes}` : minutes}
                </span>
                minutes
              </div>

              {/* <span className="sec">
                {seconds < 10 ? `0${seconds}` : seconds}
              </span> */}
            </p>
          )}
        </div>
        <CommonButton
          title="CREATE PROPOSAL"
          className="stakeButton "
          onClick={showModal}
        />
        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="createmodalSec"
          width={982}
        >
          <Form
            {...formItemLayout}
            layout={formLayout}
            form={form}
            initialValues={{ layout: formLayout }}
            onValuesChange={onFormLayoutChange}
          >
            <Form.Item label="PROPOSAL TITTLE">
              <Input />
            </Form.Item>
            <Form.Item label="PROPOSAL TYPE">
              <Radio.Group className="button" value={formLayout}>
                <Radio.Button className="modaltransparentButton">
                  NEW PROJECT
                </Radio.Button>
                <Radio.Button className="modaltransparentButton">
                  GOVERNANCE
                </Radio.Button>
                <Radio.Button className="modaltransparentButton">
                  FEED BACK
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="EMAIL">
              <Input />
            </Form.Item>
            <Form.Item label="DESCRIPTION">
              <TextArea rows={6} />
            </Form.Item>
            <div class="formbutton">
              <Form.Item {...buttonItemLayout}>
                <Button type="primary" className="modaltransparentButton">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
          
        </Modal>
      </div>
      <Row className="definationSec" gutter={10}>
        <Col xl={11} lg={12} md={12} sm={24} xs={24}>
          <CardSec />
        </Col>
        <Col xl={11} lg={12} md={12} sm={24} xs={24}>
          <CardSec />
        </Col>
      </Row>
    </div>
  );
};

export default Proposal;
