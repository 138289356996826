export const chainID = 43114
export const stakingAdr="0xF99fdA27E7F7cf6b6E76074b7Ed22A3E30121024"
export const tokenAdr = "0x822b906E74D493D07223CF6858620ccDa66b2154"
export const MAX_AMOUNT="0xffffffffffffffffffffffffffffffffffffffff"
export const TreasuryCont = "0xd905B6ecBfc0A26eb290e813E881d45b32fafF95"
export const AdvisoryCont = "0x56c6aB877913E25EaA397FbB6d7040374f9022b5"
export const FoundationCont = "0xbC9c79eebA77C81d8092E4254c920f15D497706c"
export const EcosystemCont = "0x4dC0eC6c95B66f3330191fb4Df3cF73CF6fb1e2B"
export const LiquidityCont = "0x263fD7405491547a0a9819F7F1F92f8Ec34B0AE5"
export const StakingCont = "0x253Ea5C585a64996BEDd5bE5fC25432a6229508b"
export const NftCont = "0xAad4d6269a981277dee8c4E0b94fa253BcC3bc60"
export const seed = "0xE5c8D2Bd11e8B852f8F6609B6f244A30661E3C48"

//Production*************
// Liquidity : 0x263fD7405491547a0a9819F7F1F92f8Ec34B0AE5
// staking : 0x253Ea5C585a64996BEDd5bE5fC25432a6229508b
// nft : 0xAad4d6269a981277dee8c4E0b94fa253BcC3bc60
// treasury : 0xd905B6ecBfc0A26eb290e813E881d45b32fafF95
// Advisory  : 0x56c6aB877913E25EaA397FbB6d7040374f9022b5
// Foundation : 0xbC9c79eebA77C81d8092E4254c920f15D497706c
// R&D Ecosystem : 0x4dC0eC6c95B66f3330191fb4Df3cF73CF6fb1e2B
// Seed: 0xE5c8D2Bd11e8B852f8F6609B6f244A30661E3C48
// export const stakingAdr="0xF99fdA27E7F7cf6b6E76074b7Ed22A3E30121024"
// export const tokenAdr = "0x822b906E74D493D07223CF6858620ccDa66b2154"

/******Development*****/
// Liquidity : 	0xFB346B9ffb19F3055533094C80BA2c8D55c811d4
// Staking : 0xaFc180268e0395c467f4A3Db37c1eACf3D753395
// NFT: 0x025E66934dC0938B3562860E2Fd204b2cD6c9F4F
// Tresury: 	0x1d59f6e3cc0a15687884F7F68AE3f8fcCc2c0CC8
// advisory : 	0xD150dd98441b5A2C61EBDe06555a247D9136C9fb
// Foundation : 0x83c817f911Ba77E421f67749D9aDbE5106D795a1
// R&D ecosystem: 0x3623ADd49d3580c446A7f19276eA4D88d9Be799B
//stakingcontract:0x7B3E3125bf0E5bfAb4511D00c59C40D47F428781
//token:0xAcD1Fb9B9E3ef04697d79D44f7Cc2dF2DaA21f33



