import React, { useContext, useEffect, useState } from "react";
import { Modal, Row, Col } from "antd";
import { Link } from "react-router-dom";
import "./ConnectWallet.scss";
import Matamask from "../../../assets/images/metamask_icon.png";
import Trust from "../../../assets/images/trust_wallet.svg";
import WalletConnect from "../../../assets/images/wallet_icon.svg";
import "./ConnectWallet.scss";
import { provider, walletServices } from "../../../services/walletServices";
import { toast } from "../Toasts/Toast";
import { useDispatch } from "react-redux";
import {
  fetchTokenClaim,
  ownerAddress,
} from "../../features/address/addressSlice";
import { chainID } from "../../../constants";
import { MetaProvider } from "../../hooks/networkContext";
import {isMobile} from 'react-device-detect';

const ConnectWallet = (props) => {
  const dispatch = useDispatch();
  const { snetwork } = useContext(MetaProvider);

  const handleMetamask = async () => {
    const result = window.ethereum;
    if (result && result.networkVersion != chainID) {
      await snetwork();
    }
    const account = await walletServices.isMetamaskInstalled();
    if (account) {
      toast.success("Wallet Connected");
      props.handleCancel();
      dispatch(ownerAddress(account));
      await dispatch(fetchTokenClaim(account));
      // window.location.reload();
    }
  };


  const handleWConnect = async () => {
    
    const result = window.ethereum;
    if (result && result.networkVersion != chainID) {
      await snetwork();
    }
    const account = await walletServices.walletConnect();
    console.log(account,'sadasdsaa===')
    if (account) {
      toast.success("Wallet Connected");
      props.handleCancel();
      dispatch(ownerAddress(account));
      await dispatch(fetchTokenClaim(account));
    }
  };

  return (
    <>
      <Modal
        title="Connect Wallet"
        scrollable={true}
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        className="connect_wallet"
        footer={null}
        centered
      >
        <Row>
          <Col span={24} className="design_pop">
            <ul>
              <li> 
              {!isMobile && <button
                  className="connect_btn"
                  onClick={() => handleMetamask()}
                >
                  <span>
                    <img src={Matamask} alt="icon" />
                  </span>
                  MetaMask
                </button>}
              </li>
              <li>
                <button
                  className="connect_btn"
                  onClick={() => handleWConnect()}
                >
                  <span>
                    <img src={WalletConnect} alt="icon" />
                  </span>
                  Wallet Connect
                </button>
              </li>
            </ul>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ConnectWallet;
