import { Card, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "react-spinners/RingLoader";
import { ContractServices } from "../../../services/contractService";
import { CommonButton } from "../button/CommonButton";
import { InputCustom } from "../input/InputCustom";
import { toast } from "../Toasts/Toast";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./StakeCardStyle.scss";
import { calc, contentPop } from "../../../helpers";

const StakeCard = (props) => {
  const [samt, setsamt] = useState("");
  const [loader, setloader] = useState(false);
  const [totalStake, settotalStake] = useState(0);
  const [apr, setapr] = useState(0);
  const [maxtkn, setmaxtkn] = useState(0);

  const { address } = useSelector((s) => s.address);

  const navigate = useNavigate();
  useEffect(() => {
    // if (address == "") {
    //   reset();
    //   return;
    // }
    getApr();
    maxToken();
    stakingDetail();
  }, [address]);

  const reset = () => {
    setmaxtkn(0);
    settotalStake(0);
  };

  const getApr = async () => {
    if (address == "") {
      reset();
      return;
    }
    const result = await ContractServices.stakeApr();
    setapr(result);
  };

  const handleStake = async () => {
    setloader(true);
    if (address == "") {
      setloader(false);
      return toast.error("Connect Wallet");
    }
    const result = await ContractServices.staking(samt, address);
    if (result) {
      toast.success("Token Staked Successfully");
    }
    setloader(false);
    maxToken();
    stakingDetail();
    setsamt("");
  };

  const maxToken = async () => {
    if (address == "") {
      reset();
      return;
    }
    const tknbalance = await ContractServices.maxToken(address);
    setmaxtkn(tknbalance);
    return tknbalance;
  };

  const handleMax = async () => {
    if (address == "") {
      return toast.error("Connect Wallet");
    }
    const tknbalance = await maxToken();
    setsamt(tknbalance);
  };

  const stakingDetail = async () => {
    if (address == "") {
      return;
    }
    let totalAmount = 0;
    const stakeCount = await ContractServices.getStakeCount(address);
    for (let i = 1; i <= stakeCount; i++) {
      const detail = await ContractServices.stakingDetail(address, i);
      totalAmount += detail?.amount;
    }
    totalAmount = calc(totalAmount);
    settotalStake(totalAmount);
  };

  return (
    <Card className="stakecard" bordered={false}>
      <div className="stakecard__input">
        <InputCustom
          value={samt}
          onChange={(e) => setsamt(e.target.value)}
          placeholder="Input Amount"
        />
        <CommonButton onClick={() => handleMax()} title={"max"} />
        {loader ? (
          <Loader
            color={"#fff"}
            size={20}
            css={{ margin: "0px 35px 0px 0px" }}
          />
        ) : (
          <CommonButton onClick={() => handleStake()} title={"stake"} />
        )}
      </div>
      <div className="stakecard__detail">
        <div className="stakecard__detail__apy">
          <p>Total rLoop balance:{maxtkn || 0}</p>
          <p>Total rLoop staked:{totalStake}</p>
          <h1>
            APR: <span>{apr ?apr / 10 : 0}%</span>
          </h1>
          {/* <p>
            <span>123</span> RLoop
          </p> */}
        </div>
        <div>
          <CommonButton
            disabled={loader && true}
            onClick={() => navigate("/staking-list")}
            title={"claim"}
          />
          <Popover content={contentPop} title="Note">
            <InfoCircleOutlined 
            className="stakecard__infoIcon"
            />
          </Popover>
        </div>
      </div>
    </Card>
  );
};

export default StakeCard;
